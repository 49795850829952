import { useCallback, useEffect, useState } from 'react';
import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { themeJsonLight, themeJsonDark } from './theme';


const getSurvey = async () => {
  const [_, sid, ccid] = window.location.pathname.split("/");
  try {
    const res = await fetch(process.env.REACT_APP_GET_MODEL_BASE_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify({ sid, ccid })
    });

    const responseData = await res.json();

    if (!res.ok) {
      // Use error message from server if available
      const errorMessage = responseData.message || responseData.error || 'Failed to fetch survey';
      throw new Error(errorMessage);
    }

    return responseData;
  } catch (error) {
    console.error('Survey fetch error:', error);
    throw error;
  }
};

const saveSurveyResults = async (url, json) => {
  const [_, sid, ccid] = window.location.pathname.split("/");
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify({ uid: 'weblink', sid, ccid, data: json })
    });

    const responseData = await response.json();

    if (!response.ok) {
      const errorMessage = responseData.message || responseData.error || 'Failed to save survey results';
      throw new Error(errorMessage);
    }

    console.log('Survey results saved successfully');
  } catch (error) {
    console.error('Survey save error:', error);
    throw error;
  }
};

const useThemeDetector = () => {
  const getCurrentTheme = () => window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());
  const mqListener = (e => {
    setIsDarkTheme(e.matches);
  });

  useEffect(() => {
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    darkThemeMq.addListener(mqListener);
    return () => darkThemeMq.removeListener(mqListener);
  }, []);
  return isDarkTheme;
}

function App() {
  const [surveyModel, setSurveyModel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isDarkTheme = useThemeDetector();
  const [colorScheme, setColorScheme] = useState(themeJsonDark);

  // Separate effect for theme
  useEffect(() => {
    setColorScheme(isDarkTheme ? themeJsonDark : themeJsonLight);
  }, [isDarkTheme]);

  // Separate effect for survey loading
  useEffect(() => {
    const loadSurvey = async () => {
      try {
        setLoading(true);
        const res = await getSurvey();
        const model = new Model(res.data.content);

        model.onComplete.add(alertResults);
        model.applyTheme(colorScheme);

        setSurveyModel(model);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Unknown error'));
      } finally {
        setLoading(false);
      }
    };

    loadSurvey();
  }, [colorScheme]);

  const alertResults = useCallback((sender) => {
    const results = sender.data;
    saveSurveyResults(process.env.REACT_APP_SAVE_RESPONSE_BASE_URL, results);
  }, []);

  if (loading) {
    return (
      <div style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <img src="/loader.gif" alt="loading" style={{height:"50px", width:"50px"}}/>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <h3>Error: {error.message}</h3>
      </div>
    );
  }

  return surveyModel ? <Survey model={surveyModel} /> : null;
}

export default App;